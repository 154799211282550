import React from 'react'
import Heading from '../components/typograph/Heading'
import Layout from '../components/layout/Layout'
import PageContainer from '../components/layout/PageContainer'
import Hero from '../components/hero/Hero'
import IlustratedContent from '../components/ilustratedParagraph/IlustratedContent'
import styled from 'styled-components'
import { GRAY, ACQUA } from '../constants/Colors'

import aboutUsImage from '../images/Banner-Profissionais-Desk.jpg'
import aboutUsImageMobile from '../images/Banner-Profissionais-Mobile.jpg'
import Cideli from '../images/Cideli.png'
import Adalberto from '../images/Adalberto.png'

const IlustrationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 10px;

  & .first-item {
    border-radius: 20px;
    border: 1px solid ${GRAY};
    margin-right: 80px;
    max-width: 320px;
    padding: 20px;
    margin-top: 36px;
  }

  @media (max-width: 687px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;

    & .first-item {
      margin-right: 0;
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-bottom: 64px;
  margin-top: 10px;

  & .professionals-title {
    text-align: right;
    max-width: 320px;
    margin-right: 80px;
  }

  @media (max-width: 1065px) {
    flex-wrap: wrap;

    & .professionals-title {
      text-align: center;
      max-width: 90%;
      margin-right: 0;
    }
  }
`

const Professionals = () => (
  <Layout
    title="Profissionais - HDScience"
    meta={[
      {
        name: 'description',
        content:
          'Nossos fundadores e professores atuam sempre em prol de nossos valores e compromissos.',
      },
      {
        name: 'keywords',
        content:
          'homeopatia, veterinária, cromoterapia, curso, cursos, mini-curso, mini-cursos',
      },
      {
        property: 'og:url',
        content: 'http://www.hdscience.com.br/profissionais',
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Profissionais - HDScience' },
      {
        property: 'og:description',
        content:
          'Nossos fundadores e professores atuam sempre em prol de nossos valores e compromissos.',
      },
    ]}
  >
    <Hero image={aboutUsImage} mobileImage={aboutUsImageMobile}>
      <Heading
        type="h1"
        style={{ color: '#fff', textAlign: 'center', paddingTop: 80 }}
      >
        PROFISSIONAIS
      </Heading>
    </Hero>
    <PageContainer style={{ marginBottom: 40 }}>
      <TextContainer>
        <div className="professionals-title">
          <Heading type="h3">
            Nossos fundadores e professores atuam sempre em prol de nossos
            valores e compromissos.
          </Heading>
        </div>
        <IlustrationsContainer>
          <IlustratedContent image={Cideli} className="first-item">
            <Heading type="h4" style={{ marginBottom: 16 }} color={ACQUA}>
              Profª Dra. Cidéli Coelho
            </Heading>
            Médica veterinária e fundadora <br />
            <br />
            Professora Doutora junto ao programa de Mestrado em Medicina e Bem
            Estar Animal da Universidade Santo Amaro - UNISA. Membro da
            Diretoria da AMVHB e Membro da Comissão de Homeopatia do CRMV/SP.
            Clínica em pequenos animais e silvestres.
            <br />
            <br /> A Dra. Cidéli é mestre e especialista em homeopatia pela
            AMVHB e pelo Conselho Federal de Medicina Veterinária, doutora em
            ciências na área de epidemiologia aplicada a zoonoses pela USP; e
            Pós-Doc (PhD) em Patologia Ambiental e Experimental pela UNIP.
          </IlustratedContent>
          <IlustratedContent
            image={Adalberto}
            style={{
              borderRadius: '20px',
              border: `1px solid ${GRAY}`,
              maxWidth: '320px',
              padding: 20,
              marginTop: 36,
            }}
          >
            <Heading type="h4" style={{ marginBottom: 16 }} color={ACQUA}>
              Dr. Adalberto von Ancken
            </Heading>
            Médico veterinário e co-fundador <br />
            <br /> Dr. Adalberto é autônomo e proprietário da Clínica
            Veterinária SimiliaVet, especializada em Homeopatia e localizada em
            São Paulo. <br />
            <br />É também Especialista em Homeopatia Veterinária pela AMVHB e
            especializado em Clínica e Cirurgia Veterinária. Mestre em Medicina
            e Bem-Estar Animal pela Universidade de Santo Amaro – UNISA em 2017.
            Doutorando em Patologia Ambiental e Experimental – UNIP.
          </IlustratedContent>
        </IlustrationsContainer>
      </TextContainer>
    </PageContainer>
  </Layout>
)

export default Professionals
